<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Work Planning</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Workload"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            v-model="searchTerm"
          ></v-text-field>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search By Assignee"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            v-model="searchTeamLead"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="assigned"
            label="Unassigned"
            @change="checkboxUpdated"
          ></v-checkbox>
        </v-col>

        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="$refs.workChunkForm.openForm()"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add Work</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="work_chunks"
            :items-per-page="-1"
            no-data-text="No work chunks found"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                :to="{
                  name: 'module-workload-individual',
                  params: { chunkId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </template>
            <template v-slot:item.customer.name="{ item }">
              <router-link
                v-if="item.customer"
                :to="{
                  name: 'customer-profile-basic',
                  params: { customerId: item.customer.id },
                }"
                >{{ item.customer.name }}</router-link
              >
              <div v-if="item.is_internal == 1" style="color: #cccccc">
                VITAL HIKE
              </div>
            </template>

            <template v-slot:item.groups="{ item }">
              <div v-for="group in item.groups" v-bind:key="group.id">
                {{ group.name }}
              </div>
            </template>

            <template v-slot:item.team_member="{ item }">
              <div v-if="item.team_member">
                {{ item.team_member.user.first_name }}
                {{ item.team_member.user.last_name }}
              </div>
              <div v-else style="color: #cccccc">VITAL HIKE</div>
            </template>
            <template v-slot:item.assigned="{ item }">
              <div v-if="item.assigned.length > 0">
                <div v-for="member in item.assigned" v-bind:key="member.id">
                  {{ member.first_name }} {{ member.last_name }}
                </div>
              </div>
              <div v-else style="color: #cccccc">VITAL HIKE</div>
            </template>

            <template v-slot:item.to_do="{ item }">
              {{ item.formatted_dates.to_do }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.workChunkForm.openForm(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="$refs.closeTask.openClose(item)"
                  >
                    <v-icon x-small>mdi-package-variant-closed-remove</v-icon>
                  </v-btn>
                </template>
                <span>Close</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <close-task ref="closeTask" />
    <WorkChunkForm
      ref="workChunkForm"
      :groups="groups"
      :customers="customers"
      :teamMembers="teamMembers"
    />
  </div>
</template>

<script>
import WorkChunkForm from "/src/modules/workload/views/chunks/components/WorkChunkForm.vue";
import CloseTask from "../components/CloseTask.vue";

export default {
  components: {
    WorkChunkForm,
    CloseTask,
  },

  data() {
    return {
      datePicker: false,
      statuses: ["Open", "Closed", "Deleted", "Completed"],
      types: ["Project", "Ad-hoc", "Unknown", "Contract", "Diary"],
      assigned: false,
      searchTerm: "",
      searchTeamLead: "",
      breadcrumbs: [
        {
          text: "Workload",
          disabled: true,
        },
        {
          text: "Task Planning",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Work Group", value: "groups[0].name" },
        { text: "Name", value: "name" },
        { text: "Customer", value: "customer.name" },
        { text: "Owner", value: "team_member" },
        { text: "Assigned", value: "assigned" },
        { text: "Status", value: "status" },

        { text: "Hours Logged", value: "total_time_logged.text" },
        { text: "To Do Date", value: "to_do" },
        { text: "Priority", value: "priority" },
        { text: "Actions", value: "actions", align: "right" },
      ],

      addWorkGroupForm: {
        dialog: false,
        name: null,
        loading: false,
        errors: {},
      },
      addLogTimeForm: {
        dialog: false,
        date: new Date().toISOString().slice(0, 10),
        notes: null,
        title: null,
        minutes: null,
        chunk_id: null,
        loading: false,
        errors: {},
      },

      archiveWorkloadDialog: false,
      archiveWorkloadLoading: false,
      archiveWorkloadData: null,
    };
  },

  computed: {
    work_chunks: {
      get() {
        let work_chunks =
          this.$store.getters["workload/openOrCompletedWorkChunks"];

        if (this.searchTerm !== "") {
          work_chunks = work_chunks.filter((c) => {
            const name = c.name.toLowerCase();
            const description = c.description
              ? c.description.toLowerCase()
              : "";
            const customer =
              c.customer !== null ? c.customer.name.toLowerCase() : "";
            return (
              name.includes(this.searchTerm.toLowerCase()) ||
              description.includes(this.searchTerm.toLowerCase()) ||
              customer.includes(this.searchTerm.toLowerCase())
            );
          });
        }

        if (this.assigned) {
          work_chunks = work_chunks.filter(function (item) {
            return item.assigned.length == 0;
          });
        }

        if (this.searchTeamLead !== "") {
          let applications = [];

          work_chunks.forEach((s) => {
            s.assigned.forEach((member) => {
              if (
                member.full_name.toLowerCase().indexOf(this.searchTeamLead) !=
                  -1 ||
                member.full_name.indexOf(this.searchTeamLead) != -1
              ) {
                //and if group not added yet

                const index = applications.findIndex(
                  (object) => object.id === s.id
                );

                if (index === -1) {
                  applications.push(s);
                }
              }
            });
          });
          work_chunks = applications;
        }

        return work_chunks;
      },

      set(value) {
        return value;
      },
    },
    groups() {
      return this.$store.state.workload["groups"];
    },
    customers() {
      return this.$store.state.customers["customers"];
    },
    teamMembers() {
      return this.$store.state.team["teamMembers"];
    },
  },

  watch: {},

  methods: {
    checkboxUpdated(newValue) {
      if (newValue) {
        this.assigned = true;
      }
    },

    addLogTime: function () {
      const appId = this.$route.params.id;
      const date = this.addLogTimeForm.date;
      const notes = this.addLogTimeForm.notes;
      const title = this.addLogTimeForm.title;
      const minutes = this.addLogTimeForm.minutes;
      const chunk_id = this.addLogTimeForm.chunk_id;

      this.addLogTimeForm.loading = true;
      this.addLogTimeForm.errors = {};

      this.$store
        .dispatch("workload/logTime", {
          appId,
          date,
          notes,
          title,
          minutes,
          chunk_id,
        })
        .then(() => {
          this.addLogTimeForm = {
            dialog: false,
            date: null,
            notes: null,
            title: null,
            minutes: null,
            hours: null,
            chunk_id: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.addLogTimeForm.errors = err.response.data.errors;
          this.addLogTimeForm.loading = false;
        });
    },
  },
};
</script>
